"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.I18nLanguageCodeFormat = void 0;
var I18nLanguageCodeFormat;
(function (I18nLanguageCodeFormat) {
    I18nLanguageCodeFormat[I18nLanguageCodeFormat["ISO_639_1"] = 0] = "ISO_639_1";
    I18nLanguageCodeFormat[I18nLanguageCodeFormat["ISO_3166_1_alpha_2"] = 1] = "ISO_3166_1_alpha_2";
})(I18nLanguageCodeFormat || (exports.I18nLanguageCodeFormat = I18nLanguageCodeFormat = {}));
class I18nLanguageCodeTranslator {
    formatsISO6391 = ['en', 'ja', 'uk'];
    formatsISO31661Alpha2 = ['gb', 'jp', 'ua'];
    formatsMap = new Map([
        [I18nLanguageCodeFormat.ISO_639_1, this.formatsISO6391],
        [I18nLanguageCodeFormat.ISO_3166_1_alpha_2, this.formatsISO31661Alpha2],
    ]);
    getTranslateFunc(from, to) {
        const fromCodes = this.formatsMap.get(from);
        const toCodes = this.formatsMap.get(to);
        const translationMap = new Map();
        for (let i = 0; i < fromCodes.length; i++) {
            translationMap.set(fromCodes[i], toCodes[i]);
        }
        return (code) => {
            return translationMap.get(code) ?? code;
        };
    }
}
const i18nLanguageCodeTranslator = new I18nLanguageCodeTranslator();
exports.default = i18nLanguageCodeTranslator;
