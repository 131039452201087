"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = DateDuration;
const React = require("react");
const DateUtils_1 = require("../../scripts/utils/DateUtils");
const react_i18next_1 = require("react-i18next");
function DateDuration({ fromMonthIndex = 0, fromYear = 0, toMonthIndex, toYear }) {
    const { i18n } = (0, react_i18next_1.useTranslation)();
    const from = new Date(fromYear, fromMonthIndex);
    const to = !toYear || toMonthIndex == undefined
        ? new Date()
        : new Date(toYear, toMonthIndex);
    return (React.createElement("div", { className: "controls__date-duration" }, (0, DateUtils_1.humanize)(from, to, i18n.language)));
}
