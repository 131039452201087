"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FooterContacts;
const React = require("react");
const FooterEmail_1 = require("./FooterEmail");
const FooterLocation_1 = require("./FooterLocation");
function FooterContacts() {
    return (React.createElement("div", { className: "footer-contacts" },
        React.createElement(FooterLocation_1.default, null),
        React.createElement(FooterEmail_1.default, null)));
}
