"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./styles/style.scss");
const React = require("react");
const App_1 = require("./components/App");
const AppWrapper_1 = require("./components/AppWrapper");
const LoadingSpinner_1 = require("./components/LoadingSpinner");
const client_1 = require("react-dom/client");
(0, client_1.createRoot)(document.getElementById('app')).render(React.createElement(React.Suspense, { fallback: React.createElement(LoadingSpinner_1.default, null) },
    React.createElement(AppWrapper_1.default, null,
        React.createElement(App_1.default, null))));
