"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = App;
const React = require("react");
const AboutMain_1 = require("./About/AboutMain");
const BackToTopButton_1 = require("./BackToTopButton");
const Badges_1 = require("./Badges");
const CertificatesMain_1 = require("./Certificates/CertificatesMain");
const EducationMain_1 = require("./Education/EducationMain");
const ExperienceMain_1 = require("./Experience/ExperienceMain");
const FooterMain_1 = require("./Footer/FooterMain");
const Header_1 = require("./Header");
const LanguagesMain_1 = require("./Languages/LanguagesMain");
const MenuMain_1 = require("./Menu/MenuMain");
const PortfolioMain_1 = require("./Portfolio/PortfolioMain");
const Resume_1 = require("./Resume");
const Skills_1 = require("./Skills");
const StatsMain_1 = require("./Stats/StatsMain");
const react_i18next_1 = require("react-i18next");
function App() {
    const { i18n } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { className: `font-regular font-${i18n.language === 'jp' ? '' : 'non-'}jp` },
        React.createElement(BackToTopButton_1.default, null),
        React.createElement(Header_1.default, null),
        React.createElement(MenuMain_1.default, null),
        React.createElement(AboutMain_1.default, null),
        React.createElement(StatsMain_1.default, null),
        React.createElement(Resume_1.default, null),
        React.createElement(Skills_1.default, null),
        React.createElement(ExperienceMain_1.default, null),
        React.createElement(EducationMain_1.default, null),
        React.createElement(CertificatesMain_1.default, null),
        React.createElement(LanguagesMain_1.default, null),
        React.createElement(PortfolioMain_1.default, null),
        React.createElement(Badges_1.default, null),
        React.createElement(FooterMain_1.default, null)));
}
