"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CertificatesMain;
const React = require("react");
const items = require("./items.json");
const CertificateIssuerStorage_1 = require("../../scripts/certificates/CertificateIssuerStorage");
const CertificateItem_1 = require("./CertificateItem");
const SectionTitle_1 = require("../Controls/SectionTitle");
const TechnologyStorage_1 = require("../../scripts/technologies/TechnologyStorage");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function CertificatesMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    const certificateIssuerStorage = new CertificateIssuerStorage_1.default();
    const technologyStorage = new TechnologyStorage_1.default();
    return (React.createElement("div", { id: "certificates", className: "light-component" },
        React.createElement(SectionTitle_1.default, null, t('business-card-certificates-title')),
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(react_bootstrap_1.Row, { xs: 2, md: 3, lg: 5 }, items
                .sort((a, b) => {
                let res = a.date.localeCompare(b.date);
                if (res !== 0) {
                    return -res;
                }
                res = a.issuer.localeCompare(b.issuer);
                if (res !== 0) {
                    return -res;
                }
                return -t(b.i18nTitleKey).localeCompare(t(a.i18nTitleKey));
            })
                .map((item) => React.createElement(react_bootstrap_1.Col, { lg: true, className: "mb-2", key: item.id },
                React.createElement(CertificateItem_1.default, { id: item.id, issueDate: new Date(item.date), issuer: certificateIssuerStorage.findByName(item.issuer), technology: technologyStorage.findByName(item.technology), name: t(item.i18nTitleKey), url: item.url })))))));
}
