"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Header;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function Header() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("header", { className: "header", id: "header" },
        React.createElement("div", { className: "header__text" },
            React.createElement("h1", null,
                React.createElement(react_i18next_1.Trans, { t: t }, "business-card-header-title")),
            React.createElement("h5", { className: "header__text-subtitle" }, t('business-card-header-occupation')),
            React.createElement(react_bootstrap_1.Button, { variant: "primary", size: "lg", href: "#about" }, t('business-card-header-about-me')),
            React.createElement(react_bootstrap_1.Button, { variant: "primary", size: "lg", href: "#portfolio" }, t('business-card-header-portfolio')))));
}
