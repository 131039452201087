"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FooterLocation;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function FooterLocation() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement(react_bootstrap_1.Col, null,
        React.createElement("i", { className: "fa fa-map-marker-alt fa-2x" }),
        React.createElement("p", null, t('business-card-contact-city'))));
}
