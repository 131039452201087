"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StatsGitHub;
const React = require("react");
const GitHubService_1 = require("../../scripts/services/GitHubService");
const StatsCommon_1 = require("./StatsCommon");
function StatsGitHub({ starsAmount = 57 }) {
    const [stars, setStars] = React.useState(starsAmount);
    React.useEffect(() => {
        const service = new GitHubService_1.GitHubService(stars);
        (async () => {
            const starsAmount = await service.request();
            setStars(starsAmount);
        })();
    }, []);
    return (React.createElement(StatsCommon_1.default, { techName: "gitHub", url: "https://github.com/fabasoad" },
        "\u2B50\uFE0F ",
        stars));
}
