"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = MenuMain;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
const LocaleDropDown_1 = require("./LocaleDropDown");
const MenuItem_1 = require("./MenuItem");
function MenuMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    const [expanded, setExpanded] = React.useState(false);
    const [activeNavLink, setActiveNavLink] = React.useState(document.location.hash);
    document.querySelectorAll('*').forEach((el) => {
        el.addEventListener('click', (e) => {
            const findParent = (el1, el2) => {
                if (el1 === el2) {
                    return el1;
                }
                if (el1.parentElement == null) {
                    return null;
                }
                return findParent(el1.parentElement, el2);
            };
            const element = e.target;
            const isNotNav = element.getAttribute('id') != 'nav';
            const isNotParentOfNav = findParent(document.getElementById('nav'), element) == null;
            const isNavIsNotParent = findParent(element, document.getElementById('nav')) == null;
            if (isNotNav && isNotParentOfNav && isNavIsNotParent) {
                setExpanded(false);
            }
        });
    });
    return (React.createElement("div", { className: "affix-top", id: "nav" },
        React.createElement(react_bootstrap_1.Navbar, { expanded: expanded, onToggle: setExpanded, collapseOnSelect: true, expand: "md", className: "navbar-custom" },
            React.createElement(react_bootstrap_1.Navbar.Brand, { href: "#header", className: "ms-2" }, t('business-card-author-name')),
            React.createElement(react_bootstrap_1.Navbar.Toggle, { className: "navbar-dark", "aria-controls": "basic-navbar-nav" }),
            React.createElement(react_bootstrap_1.Navbar.Collapse, { role: undefined, id: "basic-navbar-nav" },
                React.createElement(react_bootstrap_1.Nav, { activeKey: activeNavLink },
                    React.createElement(MenuItem_1.default, { setActiveNavLink: setActiveNavLink, name: 'about' }),
                    React.createElement(MenuItem_1.default, { setActiveNavLink: setActiveNavLink, name: 'skills' }),
                    React.createElement(MenuItem_1.default, { setActiveNavLink: setActiveNavLink, name: 'experience' }),
                    React.createElement(MenuItem_1.default, { setActiveNavLink: setActiveNavLink, name: 'education' }),
                    React.createElement(MenuItem_1.default, { setActiveNavLink: setActiveNavLink, name: 'portfolio' }),
                    React.createElement(MenuItem_1.default, { setActiveNavLink: setActiveNavLink, name: 'resume' }),
                    React.createElement(MenuItem_1.default, { setActiveNavLink: setActiveNavLink, name: 'badges' }),
                    React.createElement(MenuItem_1.default, { setActiveNavLink: setActiveNavLink, name: 'contact' })),
                React.createElement(react_bootstrap_1.Nav, { className: "me-2" },
                    React.createElement(LocaleDropDown_1.default, null))))));
}
