"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/// <reference path='./CertificateIssuerStorage.d.ts' />
const imgCoursera = require("../../assets/images/certificate-issuers/coursera.png");
const imgGitHub = require("../../assets/images/certificate-issuers/github.png");
const imgOracle = require("../../assets/images/certificate-issuers/oracle.png");
const imgPracticalDevSecOps = require("../../assets/images/certificate-issuers/practical-devsecops.png");
const imgUdemy = require("../../assets/images/certificate-issuers/udemy.png");
const imgHackerRank = require("../../assets/images/certificate-issuers/hacker-rank.png");
class CertificateIssuerStorage {
    provider;
    constructor(provider = {
        items: new Map([
            ['coursera', { name: 'coursera', img: imgCoursera }],
            ['github', { name: 'GitHub', img: imgGitHub }],
            ['hacker-rank', { name: 'hackerRank', img: imgHackerRank }],
            ['oracle', { name: 'oracle', img: imgOracle }],
            ['practical-devsecops', { name: 'Practical DevSecOps', img: imgPracticalDevSecOps }],
            ['udemy', { name: 'udemy', img: imgUdemy }]
        ])
    }) {
        this.provider = provider;
    }
    findByName(name) {
        return this.provider.items.has(name)
            ? this.provider.items.get(name)
            : { name };
    }
}
exports.default = CertificateIssuerStorage;
