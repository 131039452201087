"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = PortfolioItem;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
function PortfolioItem(props) {
    return (React.createElement(react_bootstrap_1.Col, { sm: 6, md: 3, lg: 3 },
        React.createElement("div", { className: "portfolio-item" },
            React.createElement("div", { className: "hover-bg text-center" },
                React.createElement("a", { href: props.url, title: props.name, rel: "noopener noreferrer", target: "_blank" },
                    React.createElement("div", { className: "hover-text" },
                        React.createElement("h4", null, props.title),
                        React.createElement("small", null, props.subtitle),
                        React.createElement("i", { className: "fa fa-search" })),
                    React.createElement("img", { className: "img-thumbnail", src: props.img, alt: props.name }))))));
}
