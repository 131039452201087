"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = JobResponsibilities;
const React = require("react");
const react_i18next_1 = require("react-i18next");
function JobResponsibilities({ i18nKeyPrefix }) {
    const { i18n, t } = (0, react_i18next_1.useTranslation)();
    const respList = [];
    const key = (x) => `${i18nKeyPrefix}-${x}`;
    for (let i = 1; i18n.exists(key(i)); i++) {
        const subList = [];
        const subKey = (x) => `${key(i)}-${x}`;
        for (let j = 1; i18n.exists(subKey(j)); j++) {
            subList.push(React.createElement("li", { key: subKey(j) }, t(subKey(j))));
        }
        respList
            .push(React.createElement("li", { key: key(i) },
            t(key(i)),
            subList.length > 0 && React.createElement("ul", null, subList)));
    }
    return (React.createElement(React.Fragment, null, respList.length > 0 &&
        React.createElement("div", { className: "job-responsibilities" },
            React.createElement("h5", null, t('business-card-experience-responsibilities-title')),
            React.createElement("ul", { className: "responsibilities" }, respList))));
}
