"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const SupportedLocales_1 = require("./SupportedLocales");
const I18nLanguageCodeTranslator_1 = require("./I18nLanguageCodeTranslator");
const I18nLanguageDetector = {
    type: 'languageDetector',
    detect: () => {
        const params = new URLSearchParams(document.location.search);
        let code = params.get('lng') ||
            localStorage.getItem('i18nextLng') ||
            SupportedLocales_1.default.default.code;
        const translate = I18nLanguageCodeTranslator_1.default.getTranslateFunc(I18nLanguageCodeTranslator_1.I18nLanguageCodeFormat.ISO_639_1, I18nLanguageCodeTranslator_1.I18nLanguageCodeFormat.ISO_3166_1_alpha_2);
        code = translate(code);
        if (document.location.search) {
            window.history.replaceState({}, document.title, document.location.origin + document.location.pathname + document.location.hash);
        }
        return code;
    },
    init: () => { },
    cacheUserLanguage: (lng) => {
        localStorage.setItem('i18nextLng', lng);
    },
};
exports.default = I18nLanguageDetector;
