"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = PortfolioMain;
/// <reference path='./PortfolioMain.d.ts' />
const React = require("react");
const imgArkaDeutscheLieferadresse = require("../../assets/images/portfolio/deutsche_lieferadresse_website.jpg");
const imgArkaInvebstor = require("../../assets/images/portfolio/invebstor.jpg");
const imgArkaMedisport = require("../../assets/images/portfolio/medisport_website.jpg");
const imgArkaPrintAndPost = require("../../assets/images/portfolio/print_and_post_website.jpg");
const imgArkaWebsite = require("../../assets/images/portfolio/arka_website.png");
const imgArkaWorkPlus = require("../../assets/images/portfolio/work_plus.png");
const imgArkaZakupki = require("../../assets/images/portfolio/zakupki_website.png");
const imgBitcoinComGames = require("../../assets/images/portfolio/bitcoin.com_games.png");
const imgBitcoinComGamesAdmin = require("../../assets/images/portfolio/bitcoin.com_games_admin.png");
const imgBitcoinComGamesAndroid = require("../../assets/images/portfolio/bitcoin.com_android.png");
const imgBitcoinComMarkets = require("../../assets/images/portfolio/bitcoin.com_markets.png");
const imgLohikaEarnin = require("../../assets/images/portfolio/earnin.png");
const imgLohikaHpeAlm = require("../../assets/images/portfolio/alm.jpg");
const imgLohikaHpeAlmRobot = require("../../assets/images/portfolio/alm_robot.png");
const imgLohikaHpeLabManagement = require("../../assets/images/portfolio/lab_management.jpg");
const imgLohikaPrysm = require("../../assets/images/portfolio/prysm_iphone_app.png");
const imgSMA = require("../../assets/images/portfolio/sma.png");
const PortfolioItem_1 = require("./PortfolioItem");
const SectionTitle_1 = require("../Controls/SectionTitle");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function PortfolioMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "portfolio" },
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(SectionTitle_1.default, null, t('business-card-portfolio-title')),
            React.createElement(react_bootstrap_1.Row, null,
                React.createElement(PortfolioItem_1.default, { url: "https://markets.bitcoin.com/", name: "Markets", title: t('business-card-portfolio-markets-title'), subtitle: t('business-card-portfolio-markets-subtitle'), img: imgBitcoinComMarkets }),
                React.createElement(PortfolioItem_1.default, { url: "https://games.bitcoin.com/", name: "Games", title: t('business-card-portfolio-casino-web-app-title'), subtitle: t('business-card-portfolio-casino-web-app-subtitle'), img: imgBitcoinComGames }),
                React.createElement(PortfolioItem_1.default, { url: "https://games.bitcoin.com/", name: "Games Admin", title: t('business-card-portfolio-casino-admin-web-app-title'), subtitle: t('business-card-portfolio-casino-admin-web-app-subtitle'), img: imgBitcoinComGamesAdmin }),
                React.createElement(PortfolioItem_1.default, { url: "https://games.bitcoin.com/android", name: "Games Android", title: t('business-card-portfolio-casino-android-app-title'), subtitle: t('business-card-portfolio-casino-android-app-subtitle'), img: imgBitcoinComGamesAndroid }),
                React.createElement(PortfolioItem_1.default, { url: "https://www.earnin.com/", name: "Earnin", title: t('business-card-portfolio-earnin-title'), subtitle: t('business-card-portfolio-earnin-subtitle'), img: imgLohikaEarnin }),
                React.createElement(PortfolioItem_1.default, { url: "https://www.prysm.com/software/iphone-app/", name: "Prysm iPhone App", title: t('business-card-portfolio-prysm-title'), subtitle: t('business-card-portfolio-prysm-subtitle'), img: imgLohikaPrysm }),
                React.createElement(PortfolioItem_1.default, { url: "https://drive.google.com/drive/folders/163jhUuVrBCEYQ503sa1Y1M7KG--1gwaj?usp=sharing", name: "Southern Marine Agency Website", title: t('business-card-portfolio-sma-title'), subtitle: t('business-card-portfolio-sma-subtitle'), img: imgSMA }),
                React.createElement(PortfolioItem_1.default, { url: "https://admhelp.microfocus.com/alm/en/12.60/online_help/Content/Admin/sa_robot_toc.htm", name: "ALM Robot", title: t('business-card-portfolio-alm-robot-title'), subtitle: t('business-card-portfolio-alm-robot-subtitle'), img: imgLohikaHpeAlmRobot }),
                React.createElement(PortfolioItem_1.default, { url: "https://www.microfocus.com/en-us/products/application-lifecycle-management/overview", name: "ALM", title: t('business-card-portfolio-alm-title'), subtitle: t('business-card-portfolio-alm-subtitle'), img: imgLohikaHpeAlm }),
                React.createElement(PortfolioItem_1.default, { url: "https://admhelp.microfocus.com/alm/en/12.60/online_help/Content/lm_ag.htm", name: "Lab Management", title: t('business-card-portfolio-alm-lab-management-title'), subtitle: t('business-card-portfolio-alm-lab-management-subtitle'), img: imgLohikaHpeLabManagement }),
                React.createElement(PortfolioItem_1.default, { url: "https://youtu.be/8wTcyZEtQx8", name: "Invebstor", title: t('business-card-portfolio-invebstor-title'), subtitle: t('business-card-portfolio-invebstor-subtitle'), img: imgArkaInvebstor }),
                React.createElement(PortfolioItem_1.default, { url: "https://youtu.be/eRxTKZ0knsg", name: "Work+", title: t('business-card-portfolio-work-plus-title'), subtitle: t('business-card-portfolio-work-plus-subtitle'), img: imgArkaWorkPlus }),
                React.createElement(PortfolioItem_1.default, { url: "https://www.arka-media.de/", name: "ARKA Website", title: t('business-card-portfolio-arka-title'), subtitle: t('business-card-portfolio-arka-subtitle'), img: imgArkaWebsite }),
                React.createElement(PortfolioItem_1.default, { url: "https://www.deutsche-lieferadresse.com/", name: "Deutsche Lieferadresse Website", title: t('business-card-portfolio-deutsche-lieferadresse-title'), subtitle: t('business-card-portfolio-deutsche-lieferadresse-subtitle'), img: imgArkaDeutscheLieferadresse }),
                React.createElement(PortfolioItem_1.default, { url: "http://medisport-garmisch.de/", name: "Medisport Website", title: t('business-card-portfolio-medisport-title'), subtitle: t('business-card-portfolio-medisport-subtitle'), img: imgArkaMedisport }),
                React.createElement(PortfolioItem_1.default, { url: "#portfolio", name: "Print & Post Website", title: t('business-card-portfolio-print-and-post-title'), subtitle: t('business-card-portfolio-print-and-post-subtitle'), img: imgArkaPrintAndPost }),
                React.createElement(PortfolioItem_1.default, { url: "https://zakupki-de.com.ua/", name: "Zakupki Website", title: t('business-card-portfolio-zakupki-title'), subtitle: t('business-card-portfolio-zakupki-subtitle'), img: imgArkaZakupki })))));
}
