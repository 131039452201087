"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StackOverflowService = void 0;
const StackExchangeService_1 = require("./StackExchangeService");
class StackOverflowService extends StackExchangeService_1.StackExchangeService {
    constructor(defaultVal) {
        super(defaultVal, 'Stack Overflow');
    }
}
exports.StackOverflowService = StackOverflowService;
