"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const JobTitle = ({ company, location, title, logo }) => {
    return (React.createElement("div", { className: "timeline-heading job-title" },
        React.createElement("h4", null,
            React.createElement("img", { src: logo, alt: company, title: company }),
            company),
        React.createElement("h4", { className: "subheading" }, title),
        React.createElement("h6", null, location)));
};
exports.default = JobTitle;
