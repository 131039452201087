"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const TechnologyStorage_1 = require("../../scripts/technologies/TechnologyStorage");
const StatsCommon = ({ techName, url, children }) => {
    const technologyStorage = new TechnologyStorage_1.default();
    const tech = technologyStorage.findByName(techName);
    return (React.createElement("div", { className: "m-4 d-flex flex-column stats-item" },
        React.createElement("a", { href: url, target: "_blank", rel: "noreferrer" },
            React.createElement("img", { src: tech.img, alt: tech.name, title: tech.title })),
        children));
};
exports.default = StatsCommon;
