"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = BackToTopButton;
const React = require("react");
function BackToTopButton() {
    const [isVisible, setIsVisible] = React.useState(false);
    window.addEventListener('scroll', () => setIsVisible(window.scrollY > 300), { passive: true });
    const handleClick = (e) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };
    return (React.createElement("a", { onClick: handleClick, className: (isVisible ? 'show ' : '') + 'back-to-top' }));
}
