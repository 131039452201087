"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDateLocale = getDateLocale;
exports.humanize = humanize;
const humanizeDuration = require("humanize-duration");
const DigitConverter_1 = require("../DigitConverter");
const react_i18next_1 = require("react-i18next");
function getDateLocale(year, monthIndex) {
    const i18n = (0, react_i18next_1.getI18n)();
    const converter = new DigitConverter_1.default();
    return i18n.language === 'jp'
        ? converter.toDoubleByte(year.toString()) + i18n.t('business-card-year-singular') + (monthIndex != undefined ? i18n.t(`business-card-month-${monthIndex}`) : '')
        : (monthIndex != undefined ? i18n.t(`business-card-month-${monthIndex}`) + ' ' : '') + year;
}
function humanize(from, to, code = 'gb') {
    const opts = {
        delimiter: ' ',
        fallbacks: ['en'],
        language: i18nCode(code),
        largest: 2,
        round: true,
        units: ['y', 'mo']
    };
    const duration = to.getTime() - from.getTime();
    return humanizeDuration(duration, opts);
}
function i18nCode(code) {
    switch (code) {
        case 'jp':
            return 'ja';
        case 'ua':
            return 'uk';
        case 'gb':
            return 'en';
        default:
            return code;
    }
}
