"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EducationItem;
const React = require("react");
const react_i18next_1 = require("react-i18next");
const DateUtils_1 = require("../../scripts/utils/DateUtils");
function EducationItem({ from, to, title }) {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "timeline-image" },
            React.createElement("h4", null,
                (0, DateUtils_1.getDateLocale)(to),
                React.createElement("br", null),
                "-",
                React.createElement("br", null),
                (0, DateUtils_1.getDateLocale)(from))),
        React.createElement("div", { className: "timeline-panel" },
            React.createElement("div", { className: "timeline-heading" },
                React.createElement("h4", null, t('business-card-education-university-title')),
                React.createElement("h4", { className: "subheading" }, title)),
            React.createElement("div", { className: "timeline-body" },
                React.createElement("p", null, t('business-card-education-university-speciality'))))));
}
