"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = LanguageItem;
const React = require("react");
const react_i18next_1 = require("react-i18next");
function LanguageItem({ children, code }) {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { className: "mb-4 mx-4" },
        React.createElement("div", { className: "h4" }, t(`business-card-languages-${code}`)),
        children));
}
