"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StatsStackExchange;
const React = require("react");
const StatsCommon_1 = require("./StatsCommon");
function StatsStackExchange({ data, service, techName, url, icon }) {
    const [stats, setStats] = React.useState(data);
    React.useEffect(() => {
        (async () => {
            const data = await service.request();
            setStats(data);
        })();
    }, []);
    return (React.createElement(StatsCommon_1.default, { techName: techName, url: url },
        icon,
        " ",
        stats.reputation));
}
