"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = StatsLeetcode;
const React = require("react");
const StatsCommon_1 = require("./StatsCommon");
const LeetcodeService_1 = require("../../scripts/services/LeetcodeService");
function StatsLeetcode({ totalSolved = 238, easySolved = 129, mediumSolved = 106, hardSolved = 3 }) {
    const [stats, setStats] = React.useState({
        totalSolved,
        easySolved,
        mediumSolved,
        hardSolved
    });
    React.useEffect(() => {
        const service = new LeetcodeService_1.LeetcodeService(stats);
        (async () => {
            const data = await service.request();
            setStats(data);
        })();
    }, []);
    return (React.createElement(StatsCommon_1.default, { techName: "leetcode", url: "https://leetcode.com/fabasoad" },
        "\u2714\uFE0F ",
        stats.totalSolved));
}
