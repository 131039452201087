"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = LocaleDropDown;
const React = require("react");
const SupportedLocales_1 = require("../../scripts/i18n/SupportedLocales");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
const I18nService_1 = require("../../scripts/i18n/I18nService");
function LocaleDropDown() {
    const { i18n, t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement(react_bootstrap_1.Dropdown, null,
        React.createElement(react_bootstrap_1.Dropdown.Toggle, { bsPrefix: "nav-link dropdown-toggle", variant: null, id: "btnLocale" },
            React.createElement("i", { className: "fa fa-globe locale-icon" }),
            t(`business-card-languages-${SupportedLocales_1.default.getOrDefault(i18n.language).code}`)),
        React.createElement(react_bootstrap_1.Dropdown.Menu, null, SupportedLocales_1.default.getExceptOf(i18n.language).map((l) => {
            return (React.createElement(react_bootstrap_1.Dropdown.Item, { onClick: () => I18nService_1.default.set(l), bsPrefix: "nav-link", eventKey: l.code, key: `dropdown-item-${l.code}` }, t(`business-card-languages-${l.code}`, { lng: l.code })));
        }))));
}
