"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const TechnologyProviderDefault_1 = require("./TechnologyProviderDefault");
class TechnologyStorage {
    provider;
    constructor(provider = TechnologyProviderDefault_1.default) {
        this.provider = provider;
    }
    findBySkill(isSkill) {
        const result = new Array();
        for (const technology of this.provider.items.values()) {
            if (technology.isSkill === isSkill) {
                result.push(technology);
            }
        }
        return result;
    }
    findByName(name) {
        return this.provider.items.has(name)
            ? this.provider.items.get(name)
            : { name, title: name };
    }
}
exports.default = TechnologyStorage;
