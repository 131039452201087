"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = JobTechnologies;
const React = require("react");
const react_i18next_1 = require("react-i18next");
const TechnologyStorage_1 = require("../../scripts/technologies/TechnologyStorage");
function JobTechnologies({ keyPrefix, names }) {
    const { t } = (0, react_i18next_1.useTranslation)();
    const technologyStorage = new TechnologyStorage_1.default();
    return (React.createElement("div", { className: "technologies" },
        React.createElement("h5", null, t('business-card-experience-technologies-title')),
        names
            .map((name) => technologyStorage.findByName(name))
            .map((t, i) => React.createElement("img", { key: `${keyPrefix}-${t.name}-${i + 1}`, src: t.img, alt: t.name, title: t.title }))));
}
