"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var State;
(function (State) {
    State[State["NOT_STARTED"] = 0] = "NOT_STARTED";
    State[State["STARTED"] = 1] = "STARTED";
    State[State["FINISHED"] = 2] = "FINISHED";
    State[State["FAILED"] = 3] = "FAILED";
})(State || (State = {}));
exports.default = State;
