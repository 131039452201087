"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.I18nServiceCallbackTypes = void 0;
const gb = require("../../assets/i18n/gb.json");
const i18n = require("i18next");
const jp = require("../../assets/i18n/jp.json");
const ua = require("../../assets/i18n/ua.json");
const I18nLanguageDetector_1 = require("./I18nLanguageDetector");
const I18nLanguageCodeTranslator_1 = require("./I18nLanguageCodeTranslator");
const SupportedLocales_1 = require("./SupportedLocales");
const react_i18next_1 = require("react-i18next");
var I18nServiceCallbackTypes;
(function (I18nServiceCallbackTypes) {
    I18nServiceCallbackTypes[I18nServiceCallbackTypes["ON_LOADED"] = 0] = "ON_LOADED";
    I18nServiceCallbackTypes[I18nServiceCallbackTypes["ON_CHANGED"] = 1] = "ON_CHANGED";
})(I18nServiceCallbackTypes || (exports.I18nServiceCallbackTypes = I18nServiceCallbackTypes = {}));
class I18nService {
    callbacks = new Map();
    constructor() {
        const translate = I18nLanguageCodeTranslator_1.default.getTranslateFunc(I18nLanguageCodeTranslator_1.I18nLanguageCodeFormat.ISO_3166_1_alpha_2, I18nLanguageCodeTranslator_1.I18nLanguageCodeFormat.ISO_639_1);
        const callback = (code, t) => {
            document.title = t('business-card-title');
            document.documentElement.setAttribute('lang', translate(code));
        };
        this.registerCallback(I18nServiceCallbackTypes.ON_LOADED, callback);
        this.registerCallback(I18nServiceCallbackTypes.ON_CHANGED, callback);
    }
    async load() {
        return i18n
            .use(I18nLanguageDetector_1.default)
            .use(react_i18next_1.initReactI18next)
            .init({
            debug: process.env.NODE_ENV === 'development',
            defaultNS: 'common',
            fallbackLng: SupportedLocales_1.default.default.code,
            ns: ['common'],
            returnNull: false,
            returnObjects: true,
            resources: {
                gb: { common: gb },
                jp: { common: jp },
                ua: { common: ua }
            }
        }).then((t) => {
            this.fireCallbacks(I18nServiceCallbackTypes.ON_LOADED, window.localStorage.i18nextLng, t);
        });
    }
    async set({ code }) {
        return i18n.changeLanguage(code, (err, t) => this.fireCallbacks(I18nServiceCallbackTypes.ON_CHANGED, code, t));
    }
    registerCallback(type, callback) {
        if (!this.callbacks.has(type)) {
            this.callbacks.set(type, []);
        }
        this.callbacks.get(type).push(callback);
    }
    fireCallbacks(type, code, t) {
        this.callbacks.get(type).forEach((c) => c(code, t));
    }
}
const i18nService = new I18nService();
exports.default = i18nService;
