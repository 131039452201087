"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EducationMain;
const React = require("react");
const EducationItem_1 = require("./EducationItem");
const SectionTitle_1 = require("../Controls/SectionTitle");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function EducationMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "education", className: "light-component" },
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(SectionTitle_1.default, null, t('business-card-education-title')),
            React.createElement(react_bootstrap_1.Row, null,
                React.createElement(react_bootstrap_1.Col, { lg: true },
                    React.createElement("ul", { className: "timeline" },
                        React.createElement("li", null,
                            React.createElement(EducationItem_1.default, { from: 2005, to: 2010, title: t('business-card-education-university-master') }))))))));
}
