"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class DigitConverter {
    toDoubleByte(num) {
        return this._convert(num, /[0-9]/g);
    }
    toSingleByte(num) {
        return this._convert(num, /[\uFF10-\uFF19]/g, -1);
    }
    _convert(num, regExp, delta = 1) {
        return num.replace(regExp, (ch) => String.fromCharCode(ch.charCodeAt(0) + (delta * 65248)));
    }
}
exports.default = DigitConverter;
