"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CertificateItem;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const DateUtils_1 = require("../../scripts/utils/DateUtils");
function CertificateItem({ issueDate, issuer, name, url }) {
    return (React.createElement(react_bootstrap_1.Card, null,
        React.createElement(react_bootstrap_1.Card.Header, null,
            issuer.img &&
                React.createElement(react_bootstrap_1.Card.Img, { src: issuer.img, className: "me-2" }),
            issuer.name),
        React.createElement(react_bootstrap_1.Card.Body, null,
            React.createElement(react_bootstrap_1.Card.Text, null,
                React.createElement(react_bootstrap_1.Card.Link, { target: "_blank", rel: "noopener noreferrer", href: url }, name))),
        React.createElement(react_bootstrap_1.Card.Footer, null, (0, DateUtils_1.getDateLocale)(issueDate.getFullYear(), issueDate.getMonth()))));
}
