"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = JobDescription;
const React = require("react");
const react_i18next_1 = require("react-i18next");
function JobDescription({ count, i18nKeyPrefix, withTitle = true }) {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { className: "job-description" },
        withTitle && React.createElement("h5", null, t('business-card-experience-description-title')),
        Array(count).fill(i18nKeyPrefix)
            .map((p, i) => `${p}-${i + 1}`)
            .map((key) => (React.createElement(react_i18next_1.Trans, { key: key, parent: "div", t: t }, key)))));
}
