"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FooterSocial;
const React = require("react");
function FooterSocial() {
    return (React.createElement("div", { className: "footer-social" },
        React.createElement("ul", { className: "icon-list" },
            React.createElement("li", null,
                React.createElement("a", { className: "btn", target: "_blank", rel: "noopener noreferrer", href: "https://twitter.com/fabasoad" },
                    React.createElement("i", { className: "fab fa-x-twitter fa-2x" }))),
            React.createElement("li", null,
                React.createElement("a", { className: "btn", target: "_blank", rel: "noopener noreferrer", href: "https://github.com/fabasoad" },
                    React.createElement("i", { className: "fab fa-github fa-2x" }))),
            React.createElement("li", null,
                React.createElement("a", { className: "btn", target: "_blank", rel: "noopener noreferrer", href: "https://www.linkedin.com/in/yevhenfabizhevskyi/" },
                    React.createElement("i", { className: "fab fa-linkedin fa-2x" }))),
            React.createElement("li", null,
                React.createElement("a", { className: "btn", target: "_blank", rel: "noopener noreferrer", href: "https://stackoverflow.com/users/470214/fabasoad" },
                    React.createElement("i", { className: "fab fa-stack-overflow fa-2x" }))),
            React.createElement("li", null,
                React.createElement("a", { className: "btn", target: "_blank", rel: "noopener noreferrer", href: "https://dev.to/fabasoad" },
                    React.createElement("i", { className: "fab fa-dev fa-2x" }))))));
}
