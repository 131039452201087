"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StackExchangeService = void 0;
const cross_fetch_1 = require("cross-fetch");
class StackExchangeService {
    stats;
    siteName;
    static STACKOVERFLOW_USER_ID = 215523;
    constructor(defaultVal, siteName) {
        this.stats = defaultVal;
        this.siteName = siteName;
    }
    async request() {
        try {
            const response = await (0, cross_fetch_1.default)(`https://api.stackexchange.com/2.3/users/${StackExchangeService.STACKOVERFLOW_USER_ID}/associated`);
            const data = await response.json();
            for (let i = 0; i < data.items.length; i++) {
                if (data.items[i].site_name === this.siteName) {
                    this.stats = {
                        answerCount: data.items[i].answer_count,
                        reputation: data.items[i].reputation
                    };
                    break;
                }
            }
        }
        catch (err) {
            console.error(err.message);
        }
        return this.stats;
    }
}
exports.StackExchangeService = StackExchangeService;
