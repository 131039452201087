"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = AppWrapper;
const React = require("react");
const ErrorBlock_1 = require("./Controls/ErrorBlock");
const LoadingSpinner_1 = require("./LoadingSpinner");
const I18nService_1 = require("../scripts/i18n/I18nService");
const DevMessageProducer_1 = require("../scripts/DevMessageProducer");
var Status;
(function (Status) {
    Status[Status["LOADING"] = 0] = "LOADING";
    Status[Status["FINISHED"] = 1] = "FINISHED";
    Status[Status["ERROR"] = 2] = "ERROR";
})(Status || (Status = {}));
function AppWrapper({ children }) {
    const [status, setStatus] = React.useState(Status.LOADING);
    I18nService_1.default.registerCallback(I18nService_1.I18nServiceCallbackTypes.ON_LOADED, () => setStatus(Status.FINISHED));
    DevMessageProducer_1.default.run();
    React.useEffect(() => {
        I18nService_1.default.load().catch((e) => {
            setStatus(Status.ERROR);
            console.error(e);
        });
    }, []);
    switch (status) {
        case Status.LOADING:
            return React.createElement(LoadingSpinner_1.default, null);
        case Status.FINISHED:
            return React.createElement(React.Fragment, null, children);
        default:
            return (React.createElement(ErrorBlock_1.default, { title: "Failed to load i18n settings" },
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://github.com/fabasoad/business-card/issues/new/choose" }, "Please raise GitHub issue")));
    }
}
