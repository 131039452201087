"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const types_1 = require("./types");
class SupportedLocales {
    static _items = [
        new types_1.Locale('gb'),
        new types_1.Locale('jp'),
        new types_1.Locale('ua')
    ];
    static default = SupportedLocales._items[0];
    static find(code) {
        return SupportedLocales._items.find((l) => l.code === code);
    }
    static getOrDefault(code) {
        return SupportedLocales.find(code) || SupportedLocales.default;
    }
    static getExceptOf(code) {
        return SupportedLocales._items.filter((l) => l.code !== code);
    }
}
exports.default = SupportedLocales;
