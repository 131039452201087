"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeetcodeService = void 0;
const State_1 = require("../../components/Stats/State");
const cross_fetch_1 = require("cross-fetch");
class LeetcodeService {
    state = State_1.default.NOT_STARTED;
    stats;
    static LEETCODE_URL = 'https://leetcode-stats-api.herokuapp.com/fabasoad';
    constructor(defaultVal) {
        this.stats = defaultVal;
    }
    async request() {
        if (this.state !== State_1.default.FINISHED && this.state !== State_1.default.STARTED) {
            this.state = State_1.default.STARTED;
            try {
                const resp = await (0, cross_fetch_1.default)(LeetcodeService.LEETCODE_URL);
                const { totalSolved, easySolved, mediumSolved, hardSolved } = await resp.json();
                this.state = State_1.default.FINISHED;
                this.stats = { totalSolved, easySolved, mediumSolved, hardSolved };
            }
            catch (err) {
                this.state = State_1.default.FAILED;
            }
        }
        return this.stats;
    }
}
exports.LeetcodeService = LeetcodeService;
