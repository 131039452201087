"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Badges;
const React = require("react");
const imgDigitalOceanBadge = require("../assets/images/badges/digital-ocean-forest-2021.png");
const SectionTitle_1 = require("./Controls/SectionTitle");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
function Badges() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "badges", className: "light-component" },
        React.createElement(SectionTitle_1.default, null, t('business-card-badges-title')),
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(react_bootstrap_1.Row, null,
                React.createElement(react_bootstrap_1.Col, { sm: true, md: true, lg: true, className: "text-center" },
                    React.createElement("a", { href: "https://tree-nation.com/profile/digitalocean", rel: "noopener noreferrer", target: "_blank" },
                        React.createElement("img", { className: "mb-4 mx-4", src: imgDigitalOceanBadge, alt: "Digital Ocean Forest Badge", title: "DigitalOcean Forest 2021" })))))));
}
