"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = DateTimeline;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const DateUtils_1 = require("../../scripts/utils/DateUtils");
const react_i18next_1 = require("react-i18next");
function DateTimeline({ fromMonthIndex, fromYear, toMonthIndex, toYear }) {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement(react_bootstrap_1.Col, { className: "controls__date-timeline" },
        React.createElement(react_bootstrap_1.Row, null, (toMonthIndex != undefined &&
            toYear && (0, DateUtils_1.getDateLocale)(toYear, toMonthIndex)) ||
            t('business-card-experience-present')),
        React.createElement(react_bootstrap_1.Row, null, "-"),
        React.createElement(react_bootstrap_1.Row, null, (0, DateUtils_1.getDateLocale)(fromYear, fromMonthIndex))));
}
