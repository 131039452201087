"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ExperienceMain;
const React = require("react");
const imgArkaFSS = require("../../assets/images/companies/arka-fss.png");
const imgBitcoinCom = require("../../assets/images/companies/bitcoin-com.png");
const imgLohika = require("../../assets/images/companies/lohika.png");
const imgWbyT = require("../../assets/images/companies/wbyt.png");
const JobDescription_1 = require("./JobDescription");
const JobPeriod_1 = require("./JobPeriod");
const JobResponsibilities_1 = require("./JobResponsibilities");
const JobTechnologies_1 = require("./JobTechnologies");
const JobTitle_1 = require("./JobTitle");
const react_bootstrap_1 = require("react-bootstrap");
const react_i18next_1 = require("react-i18next");
const SectionTitle_1 = require("../Controls/SectionTitle");
function ExperienceMain() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { id: "experience", className: "light-component" },
        React.createElement(react_bootstrap_1.Container, null,
            React.createElement(SectionTitle_1.default, null, t('business-card-experience-title')),
            React.createElement("ul", { className: "timeline" },
                React.createElement("li", { className: "timeline-inverted" },
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 10, fromYear: 2022 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgWbyT, company: "Woven by Toyota", title: t('business-card-experience-job-wbyt-6-title'), location: t('business-card-location-tokyo') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-wbyt-6-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-wbyt-6-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "wbyt-6", names: ['go', 'kubernetes', 'terraform', 'aws', 'gitHubActions', 'python', 'snyk', 'codeql', 'intellijIdea'] })))),
                React.createElement("li", null,
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 6, fromYear: 2022, toMonthIndex: 9, toYear: 2022 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgWbyT, company: "Woven by Toyota", title: t('business-card-experience-job-wbyt-5-title'), location: t('business-card-location-tokyo') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-wbyt-5-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-wbyt-5-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "wbyt-5", names: ['docker', 'intellijIdea', 'kubernetes', 'istio', 'opa', 'azureAd'] })))),
                React.createElement("li", { className: "timeline-inverted" },
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 9, fromYear: 2021, toMonthIndex: 5, toYear: 2022 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgWbyT, company: "Woven by Toyota", title: t('business-card-experience-job-wbyt-4-title'), location: t('business-card-location-tokyo') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-wbyt-4-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-wbyt-4-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "wbyt-4", names: ['docker', 'gitlab', 'gitlabCi', 'typeScript', 'react', 'maven', 'java', 'quarkus', 'mongodb', 'intellijIdea', 'concourseCI', 'kubernetes', 'terraform', 'aws', 'gitHubActions'] })))),
                React.createElement("li", null,
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 5, fromYear: 2021, toMonthIndex: 8, toYear: 2021 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgWbyT, company: "Woven by Toyota", title: t('business-card-experience-job-wbyt-3-title'), location: t('business-card-location-tokyo') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-wbyt-3-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-wbyt-3-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "wbyt-3", names: ['docker', 'gitlab', 'gitlabCi', 'typeScript', 'react', 'maven', 'java', 'quarkus', 'mongodb', 'vsCode', 'intellijIdea', 'concourseCI', 'kubernetes', 'helm', 'prometheus', 'grafana', 'terraform', 'aws'] })))),
                React.createElement("li", { className: "timeline-inverted" },
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 8, fromYear: 2020, toMonthIndex: 4, toYear: 2021 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgWbyT, company: "Woven by Toyota", title: t('business-card-experience-job-wbyt-2-title'), location: t('business-card-location-tokyo') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-wbyt-2-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-wbyt-2-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "wbyt-2", names: ['docker', 'gitlab', 'gitlabCi', 'typeScript', 'react', 'maven', 'java', 'quarkus', 'mongodb', 'vsCode', 'intellijIdea', 'concourseCI', 'kubernetes', 'helm', 'prometheus', 'grafana'] })))),
                React.createElement("li", null,
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 4, fromYear: 2020, toMonthIndex: 7, toYear: 2020 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgWbyT, company: "Woven by Toyota", title: t('business-card-experience-job-wbyt-1-title'), location: t('business-card-location-tokyo') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-wbyt-1-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-wbyt-1-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "wbyt-1", names: ['docker', 'gitlab', 'gitlabCi', 'typeScript', 'react', 'maven', 'java', 'quarkus', 'mongodb', 'vsCode', 'intellijIdea'] })))),
                React.createElement("li", { className: "timeline-inverted" },
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 6, fromYear: 2019, toMonthIndex: 3, toYear: 2020 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgBitcoinCom, company: "Bitcoin.com", title: t('business-card-experience-job-bitcoin-com-2-title'), location: t('business-card-location-tokyo') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-bitcoin-com-2-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-bitcoin-com-2-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "bitcoin-com-2", names: ['docker', 'travis', 'gitHubActions', 'bitrise', 'java', 'kafka', 'spring', 'intellijIdea'] })))),
                React.createElement("li", null,
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 8, fromYear: 2018, toMonthIndex: 5, toYear: 2019 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgBitcoinCom, company: "Bitcoin.com", title: t('business-card-experience-job-bitcoin-com-1-title'), location: t('business-card-location-tokyo') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-bitcoin-com-1-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-bitcoin-com-1-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "bitcoin-com-1", names: [
                                    'maven', 'java', 'tomcat', 'lombok', 'junit', 'python',
                                    'javaScript', 'jQuery', 'bootstrap', 'mariaDb', 'redis',
                                    'git', 'intellijIdea', 'vsCode', 'jira'
                                ] })))),
                React.createElement("li", { className: "timeline-inverted" },
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 1, fromYear: 2016, toMonthIndex: 8, toYear: 2018 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgLohika, company: "Lohika", title: t('business-card-experience-job-lohika-3-title'), location: t('business-card-location-odesa') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-lohika-3-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-lohika-3-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "lohika-3", names: [
                                    'csharp', 'xamarin', 'prism', 'dotNetCore', 'kubernetes', 'helm', 'jira',
                                    'elasticsearch', 'jenkins', 'kibana', 'fluentBit', 'fluentD', 'docker',
                                    'git', 'gitHub', 'prometheus', 'visualStudio', 'grafana', 'teamCity'
                                ] })))),
                React.createElement("li", null,
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 4, fromYear: 2013, toMonthIndex: 0, toYear: 2016 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgLohika, company: "Lohika", title: t('business-card-experience-job-lohika-2-title'), location: t('business-card-location-odesa') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-lohika-2-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-lohika-2-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "lohika-2", names: [
                                    'java', 'spring', 'maven', 'javaScript', 'angular', 'mssql',
                                    'oracle', 'svn', 'git', 'intellijIdea', 'hpeAlm', 'jenkins'
                                ] })))),
                React.createElement("li", { className: "timeline-inverted" },
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 9, fromYear: 2011, toMonthIndex: 3, toYear: 2013 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgLohika, company: "Lohika", title: t('business-card-experience-job-lohika-1-title'), location: t('business-card-location-odesa') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-lohika-1-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-lohika-1-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "lohika-1", names: ['csharp', 'windowsForms', 'svn', 'visualStudio', 'hpeAlm', 'jenkins'] })))),
                React.createElement("li", null,
                    React.createElement(JobPeriod_1.default, { fromMonthIndex: 2, fromYear: 2010, toMonthIndex: 8, toYear: 2011 }),
                    React.createElement("div", { className: "timeline-panel" },
                        React.createElement(JobTitle_1.default, { logo: imgArkaFSS, company: "ARKA - Finance, Software, Services", title: t('business-card-experience-job-arka-title'), location: t('business-card-location-odesa') }),
                        React.createElement("div", { className: "timeline-body" },
                            React.createElement(JobDescription_1.default, { count: 1, i18nKeyPrefix: "business-card-experience-job-arka-description" }),
                            React.createElement(JobResponsibilities_1.default, { i18nKeyPrefix: "business-card-experience-job-arka-responsibilities" }),
                            React.createElement(JobTechnologies_1.default, { keyPrefix: "arka", names: [
                                    'php', 'codeigniter', 'vBulletin', 'oxidEShop', 'smarty', 'javaScript', 'jQuery',
                                    'csharp', 'silverlight', 'mysql', 'svn', 'visualStudio', 'netBeans', 'redmine'
                                ] }))))))));
}
