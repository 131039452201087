"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = AboutMain;
/// <reference path='./AboutMain.d.ts' />
const React = require("react");
const react_i18next_1 = require("react-i18next");
const imgAboutMe = require("../../assets/images/about_me.jpg");
const img1z0808Badge = require("../../assets/images/badges/oracle-1z0-808.png");
const TotalExperience_1 = require("../../scripts/TotalExperience");
const SectionTitle_1 = require("../Controls/SectionTitle");
const react_bootstrap_1 = require("react-bootstrap");
function AboutMain() {
    const { t, i18n } = (0, react_i18next_1.useTranslation)();
    const totalExperience = new TotalExperience_1.default();
    return (React.createElement(react_bootstrap_1.Container, { id: "about" },
        React.createElement(SectionTitle_1.default, null, t('business-card-about-me-title')),
        React.createElement(react_bootstrap_1.Row, { className: "d-flex justify-content-center" },
            React.createElement(react_bootstrap_1.Col, { md: true, className: "about-photo d-flex justify-content-center" },
                React.createElement("img", { className: "about-photo--portrait img-thumbnail", src: imgAboutMe, alt: "About me" }),
                React.createElement("img", { className: "about-photo--badge", src: img1z0808Badge, alt: "Oracle 1Z0-808" })),
            React.createElement(react_bootstrap_1.Col, { md: { span: 8, offset: 2 } },
                React.createElement("ul", { className: "mt-3" },
                    React.createElement("li", null, t('business-card-about-me-general-list-item-1', { totalExperience: totalExperience.humanize(i18n.language) })),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-2')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-3')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-4')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-5')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-6')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-7')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-8')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-9')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-10')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-11')),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-12')),
                    React.createElement("li", null,
                        t('business-card-about-me-general-list-item-13'),
                        React.createElement("ul", null,
                            React.createElement("li", null, t('business-card-about-me-general-list-item-14')),
                            React.createElement("li", null,
                                React.createElement(react_i18next_1.Trans, { i18nKey: "business-card-about-me-general-list-item-15" },
                                    "Developed and released ",
                                    React.createElement("a", { href: "https://github.com/marketplace?type=actions&query=fabasoad+", target: "_blank", rel: "noreferrer" }, "25+ GitHub Actions to the marketplace"))),
                            React.createElement("li", null, t('business-card-about-me-general-list-item-16')))),
                    React.createElement("li", null, t('business-card-about-me-general-list-item-17')))))));
}
