"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FooterInfo;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const package_json_1 = require("../../../package.json");
const react_i18next_1 = require("react-i18next");
function FooterInfo() {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement("div", { className: "footer-info" },
        React.createElement("ul", { className: "icon-list" },
            React.createElement("li", null,
                React.createElement("img", { src: "https://img.shields.io/static/v1?label=made%20by&message=fabasoad&color=2c3e50&style=for-the-badge&logo=github", alt: "Made by fabasoad" })),
            React.createElement("li", null,
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://www.bitcoinqrcodemaker.com/?style=bitcoin&address=145HwyQAcv4vrzUumJhu7nWGAVBysX9jJH&prefix=on" },
                    React.createElement("img", { src: "https://img.shields.io/static/v1?label=bitcoin&message=donate&color=f7931a&style=for-the-badge&logo=bitcoin", alt: "Donate with Bitcoin" }))),
            React.createElement("li", null,
                React.createElement(react_bootstrap_1.Badge, { pill: true, bg: "secondary" }, t('business-card-version', { version: package_json_1.version }))))));
}
